.cs {
    height: 17px;
    position: absolute;
    width: 11px;
    cursor: pointer;
    background: url(./ColorStop.png) right center;
}

.cs div {
    height: 7px;
    left: 2px;
    width: 7px;
    position: absolute;
    top: 8px;
}

.active {
    background-position: left center;
}